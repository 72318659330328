import { useEffect, useState } from 'react';

import { io, Socket } from 'socket.io-client';

const SERVER_URL =
  process.env.DIGITAL_ID_API_BASE_URL ?? process.env.NEXT_PUBLIC_DIGITAL_ID_API_BASE_URL;
const SOCKET_TOKEN =
  process.env.DIGITAL_ID_SOCKET_TOKEN ?? process.env.NEXT_PUBLIC_DIGITAL_ID_SOCKET_TOKEN;

type Props = {
  onMessage?: (data: any) => void;
};

export function useAccessGateway(props?: Props) {
  const { onMessage } = props || {};
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const newSocket = io(SERVER_URL, {
      auth: {
        token: SOCKET_TOKEN,
      },
    });

    // Update connection status
    newSocket.on('connect', () => {
      console.log('Connected to WebSocket server');
      setIsConnected(true);
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
      setIsConnected(false);
    });

    newSocket.on('message', (data) => {
      onMessage?.(data);
    });

    setSocket(newSocket);

    // Cleanup on unmount
    return () => {
      newSocket.disconnect();
    };
  }, []);

  function subscribeAccess(id: string) {
    if (!socket) {
      return;
    }

    socket.emit('subscribe', { accessId: id });
  }

  function handleSetOnMessageCallback(callback: (data: any) => void) {
    if (!socket) {
      return;
    }

    socket.on('message', (data) => {
      callback(data);
    });
  }

  return { socket, isConnected, subscribeAccess, setOnMessageCallback: handleSetOnMessageCallback };
}
